.dropdown {
  &.dropdown_disabled {
    div,
    button {
      &:focus {
        outline: none;
      }

      cursor: default !important;
    }

    .dropdown__wrapper {
      background: #ffffff;
    }
  }
  &__wrapper {
    box-sizing: border-box;
    border: 1px solid #e6e6e6;
    box-shadow: 0 3px 6px 0 #0000000a;
    border-radius: 4px;
    position: relative;

    &.is-input-focus {
      margin-top: 20px;
    }

    &__list {
      position: absolute;
      width: 100%;
      top: 100%;
      z-index: 1;
      background-color: #ffffff;
      border: 1px solid #e6e6e6;
      border-radius: 0 0 4px 4px;
      box-shadow: 0 6px 6px 0 #00000029;
      padding: 0;
      border-top: 0;

      &__item {
        color: #1b1b1b;
        line-height: 22px;
        font-size: 16px;
        letter-spacing: 0;
        cursor: pointer;
        padding: 8px 16px;
        list-style-type: none;

        &:focus,
        &:hover {
          background-color: #e5faff;
          outline: none;
        }
      }
    }
  }
  &__header {
    display: flex;
    align-items: center;
    padding-right: 16px;

    .room-icon {
      color: #e6e6e6;
      font-size: 20px;
      padding-left: 16px;
      &.selected {
        color: #ef6439;
        font-size: 32px;
      }
    }

    .location-pin {
      width: 20px;
      margin-left: 16px;
      &.selected {
        width: 32px;
      }
    }

    .arrow-icon-btn {
      background-color: inherit;
      width: 20px;
      height: 20px;
      border: 0;
      padding: 0;
    }

    &__title {
      padding: 16px;
      color: #1b1b1b;
      line-height: 22px;
      font-size: 16px;
      letter-spacing: 0;
      font-weight: 500;
      padding-right: 0;
      flex: 1;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .location-dropdown {
    .dropdown__header__title {
      padding-left: 6px;
    }
  }
}
