.footer-cont {
    background-color: white;
    padding: 50px 0 100px;

    @media (max-width: 768px) {
        padding: 15px;
        margin: 1rem 0;
        overflow: hidden;
    } 

    &__text {
        color: #2E2E2E;
        font-size: 16px;
        line-height: 24px;
        max-width: 834px;
        margin-bottom: 0;
    }
}

@media (max-width: 768px) {
    .site-footer {
        margin-top: 50px;
    
        .region-footer {
            padding: 15px 0;
    
            .list-inline {
                padding-left: 0;
                margin: 2rem 0;
    
                .list-inline-item {
                    display: block;
                    text-align: center;
                    margin: 1rem 0;
                }
            }
        }
    }
} 

