.store-list-container {

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }


  .list-container {
    height: 606px;
    overflow-y: auto;
    width: 332px;
    scroll-behavior: smooth;

    @media (max-width: 768px) {
      max-height: 300px;
      width: 100%;
      margin: 1rem 0;
    }

    * {
      // for firefox
      scrollbar-color: #e6e6e6 #ffffff;
      scrollbar-width: thin;
    }
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: #e6e6e6;
    }
    &-wrapper {
      position: relative;
      &::before {
        content: "";
        background: transparent
          linear-gradient(180deg, #ffffff00 0%, #808080 100%) 0% 0% no-repeat
          padding-box;
        opacity: 0.15;
        height: 36px;
        position: absolute;
        bottom: 0;
        width: 95%;
      }
    }
    &__item {
      padding: 16px 24px;
      border: 1px solid #e6e6e6;
      border-radius: 8px;
      margin-bottom: 8px;
      margin-right: 8px;
      &:last-child {
        margin-bottom: 0;
      }
      &-category {
        color: #757575;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 4px;
      }
      &-name {
        color: #171717;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        margin-bottom: 2px;
      }
      &-address {
        color: #454545;
        font-size: 14px;
        line-height: 18px;
      }
      &-otherinfo {
        color: #18a872;
        font-size: 10px;
        line-height: 13px;
        margin-top: 4px;
      }
      &:hover {
        background-color: #fffbf8;
      }
      &.selected {
        border: 2px solid #ef5e25;
      }
    }
  }

  .no-list-cont {
    height: 606px;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 33px;

    @media (max-width: 768px) {
      height: auto;
      padding: 15px;
      margin: 1rem 0;
    }
    
    .no-list-text {
      font-size: 24px;
      color: #171717;
      line-height: 32px;
      font-weight: 500;
      margin-bottom: 16px;
    }
    .reset-button {
      border: 0;
      background-color: transparent;
      color: #189fc1;
      font-size: 16px;
      line-height: 22px;
      min-width: 258px;
    }
  }
}
