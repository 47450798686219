.search-container {
    margin-bottom: 40px;

    @media (max-width: 768px) {
        margin: 0;
    }
    &__tempimg{
        padding: 90px 0 30px;
        display: 'block';
        justify-content: 'right';
        height: '100vh';
    }       
    
    
    &__title {
        padding: 60px 0 30px;
        display: flex;
        justify-content: center;
        
        @media (max-width: 768px) {
            margin: 1rem 0;
            padding: 0;
        }

        &-text {
            color: #3D231D;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: 0;
            max-width: 664px;
        }
    }
    &__main {

        padding: 16px 24px;
        border: 2px solid #e6e6e6;
        border-radius: 8px;
        margin-bottom: 8px;
        margin-right: 8px;
        justify-content: space-around;

        @media (max-width: 768px) {
            margin-bottom: 15px;
            display: block !important;
        }

        &-section {
            margin-right: 18px;
            

            @media (max-width: 768px) {
                margin: 1rem 0;
            }

            .look-to-dropdown{
                min-width: 158px;
            }
            .items-dropdown, .location-dropdown {
                min-width: 290px;
            }
            
        }
        &-title {
            color: #757575;
            font-size: 16px;
            line-height: 22px;            
            margin-bottom: 12px;
        }
        &-btn {
            align-self: flex-end;
            background-color: #1282A2;
            border: 1px solid #1282A2;
            border-radius: 4px;
            padding: 16px 66px;
            font-size: 16px;
            line-height: 21px;
            color: #FFFFFF;
            min-width: 208px;
            //width: 100%;           
            &:hover {
                background-color: #0C687D;
                border: 1px solid #0C687D;
            }

            @media (max-width: 768px) {
                margin: 0.5rem auto;
            }
        }
    }
    .error-msg {
        color: #e73d3d;

        @media (max-width: 768px) {
            display: block !important;
            margin-top: 1rem !important;
        }
    }
}