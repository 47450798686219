body {
  margin: 0;
  padding: 0;
  font-family: "IBM Plex Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0;

  &.no-scroll {
    overflow: hidden;
  }
}
.container {
  max-width: 1000px;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin-bottom: 0;
}

a {
  color: #0050d8;
  font-size: 16px;
  line-height: 24px;
}

@import "ARR-app/components/common/Header/index.scss";
@import "ARR-app/components/common/Footer/index.scss";
@import "ARR-app/components/containers/AustinReuseDirectory/index.scss";
@import "ARR-app/components/containers/SearchComponent/index.scss";
@import "ARR-app/components/common/Dropdown/index.scss";
@import "ARR-app/components/containers/ResultsContainer/index.scss";
@import "ARR-app/components/containers/StoreList/index.scss";
@import "ARR-app/components/containers/StoreMap/index.scss";
@import "ARR-app/components/containers/MoreInformation/index.scss";
@import "ARR-app/components/containers/StoreInformation/index.scss";
