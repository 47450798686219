.header-cont {
    background-color: #FFFBF8;
    padding: 27px 51px 26px;
    margin-top: 80px;
    
    &__title {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0;
        color: #EF6439; 
        margin-bottom: 0;       
    }
}

.navbar-brand span{
    color: #ff705d;
}

.goog-te-combo, .notranslate{
    background: #212529 !important;
    border-color: #212529 !important;
    -webkit-appearance: none;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.55);
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-size: 16px;
    font-family: "IBM Plex Sans", sans-serif;
    cursor: pointer;
    margin-top: 10px;

    &:hover, &:focus {
        color: rgba(255, 255, 255, 0.75);
    }

    @media (max-width: 768px) {
        margin-left: -8px !important;
    }
}

.goog-te-combo {
    display: none;
}
