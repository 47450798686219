.store-info-container {
  position: absolute;
  top: 0;
  width: 494px;
  margin: 8px;
  background-color: #ffffff;
  box-shadow: 0 6px 6px 0 #00000029;
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    border-radius: 0;
    left: 0;
    z-index: 2000;
    height: 100vh;
    overflow: scroll;
  }

  .store-address {
    background-color: #fffbf8;
    padding: 24px;
    border-radius: 8px 8px 0 0;
    .store-category {
      font-size: 14px;
      color: #757575;
      line-height: 18px;
      flex: 1;
    }    
    .store-name {
      font-size: 32px;
      color: #fd581f;
      line-height: 42px;
      font-weight: 500;
      margin-bottom: 8px;
    }    
    .address {
      font-size: 16px;
      color: #3d231d;
      line-height: 24px;
    }
    .directions-link {
      text-decoration: underline;
      border: none;
      background: none;
      color: #0050d8;
      padding: 0;
      .launch {
        width: 7px;
        height: 7px;
        font-size: 7px;
        margin: 3px;
      }
    }
    .close-btn {
      border: 0;
      background-color: transparent;
      padding: 5px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      &:hover {
        background-color: #fff3ea;
      }
      .close {
        color: #ef6439;
        width: 14px;
        height: 14px;
        font-size: 14px;
      }
    }
  }
  .separator {
    border-bottom: 1px solid #e6e6e6;
    margin: 24px 33px 24px 30px;
  }
  .heading {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: #171717;
    margin-bottom: 8px;
  }
  .title {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: #171717;
    margin-bottom: 8px;
  }
  .actions {
    font-weight: bold;
    color: #171717;
    margin-bottom: 8px;
  }
  .bold-space {
    margin-left: -12px;
  }
  .no-bold {
    font-weight: normal;
  }
  .blue-container {
    background-color: #e5faff;
    border-radius: 4px;
    padding: 16px;
    margin: 0 33px 0 30px;
  }
  .left-right-30 {
    margin: 0 33px 0 30px;
  }
  .left-30 {
    margin-left: 30px;
  }
  .other-info {
    color: #18a872;
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
  }
  .store-info {
    color: #454545;
    font-size: 18px;
    line-height: 24px;
  }
  .store-number,
  .store-site {
    span {
      width: 66px;
      color: #5c5c5c;
      font-size: 16px;
      line-height: 24px;
      display: inline-block;
    }
  }
  .store-site {
    margin-bottom: 12px;
  }
  ul {
    padding: 0;
    margin-left: 16px;
  }
  .timings {
    margin-bottom: 4px;
    white-space: pre-line;
    span {
      &:first-child {
        width: 90px;
        display: inline-block;
        margin-right: 16px;
      }
    }
  }
}
