.more-info-container {
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 80px;

  @media (max-width: 768px) {
    padding: 0 15px;
    overflow: hidden;
  } 

  &__content {
    max-width: 834px;
    margin: 0 auto;
    .more-info-text {
      margin-left: 24px;
      max-width: 333px;
      color: #454545;
      font-size: 16px;
      line-height: 24px;
    }

    &_section {
      @media (max-width: 768px) {
        display: block !important;
      } 
    }

    .link-container {
      background-color: #ffffff;
      padding: 28px 24px;
    }
  }
}
