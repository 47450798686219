.gm-style-iw + button {
  display: none;
}
a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none !important;
}
.gmnoprint div {
  background: none !important;
}

.map-container {
  @media (max-width: 768px) {
    height: 300px !important;
    margin: 1rem 0;
  }

  h6 {
    margin-bottom: 0;
  }

  .link-click {
    text-decoration: underline;
    float: right;
    font-size: 12px;
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
  }
}
