.results-container {
  margin-bottom: 80px;
  height: 606px;

  @media (max-width: 768px) {
    height: auto;
    margin: 0;
    width: 100%;
    display: block !important;
  }
}

.map-block {
  
  @media (min-width: 768px) {
    position: relative;
  }

  @media (max-width: 768px) {
    margin: 0 !important;
  }
}
